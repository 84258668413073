import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
            debugger;
        // check some condition
        if (!sessionStorage.getItem('RegUser')) {
            //  alert('You are not allowed to view this page');
            // redirect to login/home page etc
            // return false to cancel the navigation
            this.router.navigateByUrl('/login')
            return false;
        }
        return true;
    }

}
