<mat-dialog-content>
    <html>

    <head>
        <style>
            input,
            select,
            textarea {
                padding: 0px 0px;
                border: 1px solid #ffffff;
                font-weight: bold;
                /* border-radius: 0; */
            }
            
            td {
                width: 50%;
            }
        </style>
    </head>

    <body>
        <form ngNoForm method="post" name="customerData" action="https://amigosbooks.com/payment/ccavRequestHandler.php">

            <table width="100%" align="center">
                <input type="hidden" name="redirect_url" value="https://amigosbooks.com/payment/ccavResponseHandler.php" readonly />
                <input type="hidden" name="cancel_url" value="https://amigosbooks.com/payment/ccavResponseHandler.php" readonly />
                <input type="hidden" name="language" value="EN" readonly />
                <input type="hidden" name="integration_type" value="iframe_normal" readonly />
                <input type="hidden" name="merchant_id" value="119058" readonly />
                <input type="hidden" name="tid" id="tid" value="{{checkoutData.tid}}" readonly />



                <input type="hidden" name="first_name" value="{{checkoutData.first_name}}" readonly />
                <input type="hidden" name="last_name" value="{{checkoutData.last_name}}" readonly />
                <input type="hidden" name="address1" value="{{checkoutData.address1}}" readonly />
                <input type="hidden" name="address2" value="{{checkoutData.address2}}" readonly />
                <input type="hidden" name="notes" value="{{checkoutData.notes}}" readonly />
                <input type="hidden" name="amount" value="{{checkoutData.amount}}" readonly />
                <input type="hidden" name="Orginal_Shipping_Amout" value="{{checkoutData.Orginal_Shipping_Amout}}" readonly />
                <input type="hidden" name="After_Dicount_Shipping_Amout" value="{{checkoutData.After_Dicount_Shipping_Amout}}" readonly />
                <input type="hidden" name="Dicount_Shipping_Amout" value="{{checkoutData.Dicount_Shipping_Amout}}" readonly />
                <input type="hidden" name="terms" value="{{checkoutData.terms}}" readonly />



                <div>
                    <div style="text-align: right;
                    "> <button TYPE="submit" [disabled]="customeridnull" class="btn btn-success">Confirm</button>
                    </div>
                    <img src="https://amigosbooks.com/assets/image/DeepthiLogo.png" alt="" style="height: 70px;">

                </div>
                <tr>
                    <td colspan="2"> <br>
                        <h5><strong>Confirm Order</strong></h5>

                    </td>

                </tr>

                <tr>
                    <td>School Name : <br> <input type="text" name="billing_country" value="{{checkoutData.country}}" readonly /></td>

                </tr>
                <tr>
                    <td>Order Id : <br> <input type="text" name="order_id" value="{{checkoutData.order_id}}" readonly /></td>


                </tr>
                <tr>
                    <td>Amount : <br><input type="text" name="amount" value="{{checkoutData.amount}}" readonly /></td>

                </tr>
                <tr>
                    <td>Currency : <br><input type="text" name="currency" value="INR" readonly /></td>

                </tr>

                <tr>
                    <td>Billing Name : <br><input type="text" name="billing_name" value="{{checkoutData.first_name}}" readonly /></td>

                </tr>
                <tr>
                    <td>Billing Address :<br><input type="text" name="billing_address" value="{{checkoutData.address1}}{{checkoutData.address2}}" readonly /></td>

                </tr>
                <tr>
                    <td>Billing City :<br><input type="text" name="billing_city" value="{{checkoutData.city}}" readonly/></td>

                </tr>
                <tr>
                    <td>Billing State :<br><input type="text" name="billing_state" value="{{checkoutData.state}}" readonly /></td>

                </tr>
                <tr>
                    <td>Billing Zip : <br><input type="text" name="billing_zip" value="{{checkoutData.zipcode}}" readonly/></td>

                </tr>

                <tr>
                    <td>Billing Tel : <br> <input type="text" name="billing_tel" value="{{checkoutData.altno}}" readonly /></td>

                </tr>
                <tr>
                    <td>Billing Email : <br> <input type="text" name="billing_email" value="{{checkoutData.emailid}}" readonly/> </td>


                </tr>
                <tr style="display:none">
                    <td> <input type="text" name="customer_id" value="{{checkoutData.customer_id}}" readonly/></td>

                    <td>
                        <input type="text" name="products" value="{{checkoutData.products}}" readonly/>
                        <input type="text" name="gatewaycharges" value="{{checkoutData.gatewaycharges}}" readonly/>
                    </td>


                </tr>

            </table>
            <div style="text-align: center;"> <button TYPE="submit" [disabled]="customeridnull" class="btn btn-success">Confirm</button>
            </div>
        </form>
    </body>

    </html>
</mat-dialog-content>