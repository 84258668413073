<div class=" " *ngIf="trackinghistor && trackinghistor.length>0">
    <div class=" ">
        <div class="row">
            <div class="col-7"> <span id="heading">Date</span><br> <span id="details"><strong> {{trackinghistor[0].date_paid | date: 'dd/MMM/yyyy'}}</strong></span> </div>
            <div class="col-5 pull-right"> <span id="heading">Order No.</span><br> <span id="details"><strong>{{trackinghistor[0].order_number}}</strong></span> </div>
        </div>
    </div>
    <hr>
    <!-- <div class=" ">
        <div class="row">
            <div class="col-9"> <span id="name">Grad Total</span> </div>
            <div class="col-6"> <span id="price">₹  {{trackinghistor[0].amount - trackinghistor[0].discount_shipping_amount}}</span> </div>
        </div> -->
    <!-- <div class="row">
            <div class="col-9"> <span id="name">Shipping</span> </div>
            <div class="col-3"> <span id="price">₹ {{trackinghistor[0].discount_shipping_amount}}</span> </div>
        </div> -->
    <!-- </div> -->
    <!-- <div class=" ">
        <div class="row">
            <div class="col-9"></div>
            <div class="col-3">₹ {{trackinghistor[0].amount}}</div>
        </div>
    </div> -->
    <div class="tracking">
        <div class="title">Tracking Order</div>
    </div>
    <!-- <div>
        <ul id="progressbar">
            <li class="step0" id="step1" [ngClass]="fnchecktrackerStatus('Payment Successful') ? 'active' : 'inactive'"><strong> Payment Successful</strong> <br>{{fnchecktrackerDate('Payment Successful') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="step0" id="step2" [ngClass]="fnchecktrackerStatus('Preparing for Dispatch') ? 'active' : 'inactive'"><strong>Preparing for Dispatch </strong><br>{{fnchecktrackerDate('Preparing for Dispatch') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="step0" id="step3" [ngClass]="fnchecktrackerStatus('Ready to Ship') ? 'active' : 'inactive'"><strong>Ready to Ship </strong><br>{{fnchecktrackerDate('Ready to Ship') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="step0" id="step4" [ngClass]="fnchecktrackerStatus('Out for Delivery') ? 'active' : 'inactive'"><strong>Out for Delivery </strong><br>{{fnchecktrackerDate('Out for Delivery') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="step0" id="step4" [ngClass]="fnchecktrackerStatus('Delivered') ? 'active' : 'inactive'"><strong>Delivered </strong><br>{{fnchecktrackerDate('Delivered') | date: 'dd/MM/yyyy HH:mm a' }} </li>
        </ul>
    </div> -->

    <div class="wrapper">
        <ul class="StepProgress">


            <li class="StepProgress-item" id="step1" [ngClass]="fnchecktrackerStatus('Payment Successful') ? 'is-done' : 'inactive'"><strong> Payment Successful</strong> <br>{{fnchecktrackerDate('Payment Successful') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="StepProgress-item" id="step2" [ngClass]="fnchecktrackerStatus('Preparing for Dispatch') ? 'is-done' : 'inactive'"><strong>Preparing for Dispatch </strong><br>{{fnchecktrackerDate('Preparing for Dispatch') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="StepProgress-item" id="step3" [ngClass]="fnchecktrackerStatus('Ready to Ship') ? 'is-done' : 'inactive'"><strong>Ready to Ship </strong><br>{{fnchecktrackerDate('Ready to Ship') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="StepProgress-item" id="step4" [ngClass]="fnchecktrackerStatus('Out for Delivery') ? 'is-done' : 'inactive'"><strong>Out for Delivery </strong><br>{{fnchecktrackerDate('Out for Delivery') | date: 'dd/MM/yyyy HH:mm a'}} </li>
            <li class="StepProgress-item" id="step4" [ngClass]="fnchecktrackerStatus('Delivered') ? 'is-done' : 'inactive'"><strong>Delivered </strong><br>{{fnchecktrackerDate('Delivered') | date: 'dd/MM/yyyy HH:mm a' }} </li>
        </ul>
    </div>


</div>