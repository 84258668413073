import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { EcommerceService } from '../ecommerce.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HowtogetSchoolIdComponent } from '../howtoget-school-id/howtoget-school-id.component';
declare var $: any;
export interface User {
  school_code: string;
  school_name: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  selected_class = new FormControl('');
  classfilter = new FormControl();
  // schoolCode = new FormControl();
  public shownameandfilter = false;
  // tslint:disable-next-line:variable-name
  productObject_main: any;
  productObject: any;
  classObjecMaster: any;
  classObject: any;
  schoolcodelist: any = [];
  DisplaySchoolName = 'School';
  simpleproductObject: any;
  SelectedSchool: any = [];
  cartProducts: any = [];
  orders: string;

  constructor(private renderer: Renderer2,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private service: EcommerceService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  public schoolCode = '';
  filteredOptionscode: Observable<User[]>;
  ngOnInit() {

    this.showClass();
    this.showSchoolist();
    this.showAllSimpleProducts();

    if (this.route.snapshot.queryParams.id) {
      localStorage.setItem('sccode', this.route.snapshot.queryParams.id);
      debugger
      $('#scode').val(this.route.snapshot.queryParams.id);
      this.getProducts();
    } else {
      localStorage.getItem('sccode');
      debugger
      $('#scode').val(localStorage.getItem('sccode'));
      this.getProducts();
    }


  }
  addtoCartfun(product) {
    if (!this.IsProductAddedToCart(product.id)) {
      this.service.AddProductTocart(product);
      this.cartProducts = this.service.GetAddedCart();
      this.toastr.success('item Added', product.name, {
        timeOut: 6000, positionClass: 'toast-center-center',
      });
    }
  }
  IsProductAddedToCart(productId: number): boolean {
    this.cartProducts = this.service.GetAddedCart();
    return (this.cartProducts.filter(element => element.id === productId).length > 0);
  }
  addtoCheckoutfun(product) {
    this.service.AddProductTocart(product);
    this.toastr.success('item Added', product.name);
    this.router.navigateByUrl('/checkout');
  }
  getProducts() {
    debugger
    const schoolcode = $('#scode').val().toUpperCase();

    this.service.getProductsbySchoolCode(schoolcode.toUpperCase()).subscribe((result: any) => {
      debugger
      if (result.data === 'nodata') {
        // alert('Enter valid school code!');
        this.toastr.error('Enter valid school code!');
      } else {
        this.productObject_main = result.data;
        //   console.log(result.data, 'ganana new re')
        this.DisplaySchoolName = 'School';
        this.productObject = [];
        this.classObject = [];
        this.SelectedSchool = [];
        if (this.schoolcodelist.filter(i => i.school_code === schoolcode).length > 0) {
          this.shownameandfilter = true;
        } else {
          this.shownameandfilter = false;
        }
        setTimeout(() => {
          const errorField = this.renderer.selectRootElement('.grid-list-selector');
          errorField.scrollIntoView();
        }, 200);

      }



      if (this.schoolcodelist.filter(i => i.school_code === schoolcode).length > 0) {
        debugger;
        this.SelectedSchool = this.schoolcodelist.filter(i => i.school_code === schoolcode)[0];
        this.DisplaySchoolName = this.SelectedSchool.school_name.toUpperCase();
        this.productObject = this.productObject_main;
        //.filter(i => i.school_code === this.SelectedSchool.id);
        this.productObject.forEach(element => {
          debugger;
          const classval = this.classObjecMaster.filter(i => i.id === element.class)[0];
          if (this.classObject.filter(j => j.id === classval.id).length === 0) {
            this.classObject.push(classval);
          }
        });
        // this.selected_class = this.classObject;
        // this.onClassChange();
      }


    });



  }
  onClassChange() {

    if (this.selected_class.value === null || this.selected_class.value.length === 0) {
      this.productObject = this.productObject_main.filter(i => i.school_code === this.SelectedSchool.school_code);
    } else {
      // tslint:disable-next-line:max-line-length
      this.productObject = this.productObject_main.filter(i => i.school_code === this.SelectedSchool.school_code
        && this.selected_class.value.filter(ele => ele.id === i.class).length > 0);
    }
  }
  // showAllProducts() {

  // }
  showAllSimpleProducts() {
    this.service.getSimpleProducts().subscribe((result: any) => {
      this.simpleproductObject = result.data;
    });
  }
  showClass() {
    this.service.getClass().subscribe((result: any) => {
      this.classObjecMaster = result.data;
    });
  }
  showSchoolist() {
    this.service.getSchoolList().subscribe((result: any) => {
      // tslint:disable-next-line:no-
      ;
      this.schoolcodelist = result.data;
    });
  }
  openSchoolID() {
    this.dialog.open(HowtogetSchoolIdComponent, { width: '400px' });

  }


}
