<section class="content-main">



    <div class="card mb-4">

        <!-- card-header end// -->
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>#Order No</th>
                            <th>#Transaction No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Address</th>
                            <!-- <th scope="col">Total</th> -->
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Tracking</th>
                            <th scope="col">Order Details</th>
                            <th scope="col">Invoice</th>
                            <th scope="col">Date</th>
                            <!-- <th scope="col" class="text-end"> Action </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ordersList; let i = index">
                            <td> {{i+1}}</td>
                            <td>{{item.ord_ordernumber}}</td>
                            <td>{{item.ord_transaction_id}}</td>
                            <td><b>{{item.cus_firstname}} {{item.cus_lastname}}</b></td>
                            <td title=" {{item.ship_address2}}, {{item.ship_state}}, {{item.ship_city}}, {{item.cus_email}}">{{item.ship_address1}}...</td>
                            <!-- <td>₹{{item.pro_amouttotal}}.00</td> -->
                            <td><span class="badge rounded-pill " [ngClass]="item.ord_paymentStatus == 'Success' ? 'alert-success' : 'alert-warning'">{{item.ord_paymentStatus}}</span></td>

                            <td *ngIf="item.ord_paymentStatus == 'Success'" (click)="openOrderTracking(item.ord_ordernumber)"><b class="btn btn-dark btn-sm" style="cursor: pointer;">View</b></td>
                            <td *ngIf="item.ord_paymentStatus == 'Aborted'">---</td>
                            <td *ngIf="item.ord_paymentStatus == 'Invalid'">---</td>
                            <td *ngIf="item.ord_paymentStatus == 'cancelled'">---</td>
                            <td *ngIf="item.ord_paymentStatus == 'Failure'">---</td>
                            <td *ngIf="item.ord_paymentStatus == 'Awaited'">---</td>
                            <td *ngIf="item.ord_paymentStatus == ''">---</td>
                            <td (click)="openOrderDetails(item.ord_ordernumber)"><b class="btn btn-dark btn-sm" style="cursor: pointer;">View</b></td>
                            <td> <span *ngIf="item.ord_paymentStatus != 'Success'">---</span> <b (click)="openInvoice(item.ord_ordernumber)" *ngIf="item.ord_paymentStatus == 'Success'" class="btn btn-dark btn-sm" style="cursor: pointer;">View</b></td>

                            <td>{{item.ord_datepaid | date: 'medium' }}</td>
                            <!-- <td class="text-end"> -->
                            <!-- <a class="btn btn-light">Detail</a>
                                <div class="dropdown">
                                    <a href="#" data-bs-toggle="dropdown" class="btn btn-light"> <i class="material-icons md-more_horiz"></i> </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item text-danger" href="#">Delete</a>
                                    </div>
                                </div> -->
                            <!-- dropdown //end -->
                            <!-- </td> -->
                        </tr>


                    </tbody>
                </table>
            </div>
            <!-- table-responsive //end -->
        </div>
        <!-- card-body end// -->
    </div>
    <!-- card end// -->


</section>