import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EcommerceService } from '../ecommerce.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private toastr: ToastrService, private router: Router,
    private activeRouter: ActivatedRoute,
    private service: EcommerceService,
    public dialogRef: MatDialogRef<LoginComponent>) { }

  ngOnInit() {
    sessionStorage.removeItem('RegUser');
    this.service.SetIsLogin();
    this.loginForm = this.fb.group({
      number: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  btnForgotPwdClick() {
    if (this.dialogRef.close !== undefined) {
      this.dialogRef.close('forgot');
    } else {
      this.router.navigate(['/forgotpassword']);
    }
    // this.router.navigate(['/forgotpassword'], {relativeTo: this.activeRouter,skipLocationChange:true});
  }
  btnGigisterClick() {
    if (this.dialogRef.close !== undefined) {
      this.dialogRef.close('register');
    } else {
      this.router.navigate(['/register']);
    }

  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  funLoginUser() {
    if (this.loginForm.valid) {
      this.service.loginUser(this.loginForm.value).subscribe((results: any) => {
        if (results && results.data.indexOf('success') > -1) {
          this.toastr.success('Logged', 'Successfully!');
          sessionStorage.setItem('RegUser', this.loginForm.controls.number.value);
          this.service.SetIsLogin();
          if (this.dialogRef.close !== undefined) {
            debugger
            this.dialogRef.close();
            sessionStorage.setItem('RegUser', this.loginForm.controls.number.value);
          } else {
            if (sessionStorage.getItem('CartProducts')) {
              this.router.navigateByUrl('/checkout');
            } else {
              this.router.navigateByUrl('/home');
            }
          }
          this.loginForm.reset();
        } else {
          this.toastr.error(results.data);
        }
      });
    } else {
      this.toastr.error('enter all inputs');
    }
  }
}
