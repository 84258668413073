import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AuthGuardService } from './authguard';
import { BesafeComponent } from './besafe/besafe.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { CartComponent } from './cart/cart.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { DisputeResolutionComponent } from './dispute-resolution/dispute-resolution.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { GeneralTermsandConditionsComponent } from './general-termsand-conditions/general-termsand-conditions.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { RegisterComponent } from './register/register.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'besafe', component: BesafeComponent },
  { path: 'photogallery', component: PhotogalleryComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckOutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'myaccount', component: CustomerDashboardComponent, canActivate : [AuthGuardService]  },
  { path: 'ContactInformation', component: ContactInformationComponent},
  { path: 'PrivacyPolicy', component: PrivacyPolicyComponent},
  { path: 'RefundPolicy', component: RefundPolicyComponent},
  { path: 'ReturnPolicy', component:  ReturnPolicyComponent},
  { path: 'DisputeResolution', component:  DisputeResolutionComponent},
  { path: 'GeneralTermsandConditions', component:  GeneralTermsandConditionsComponent},
  { path: 'ShippingPolicy', component: ShippingPolicyComponent},
  { path: 'CancellationPolicy', component: CancellationPolicyComponent},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
