<!-- breadcrumb start -->

<section class="about-breadcrumb">
  <div
    class="about-back section-tb-padding"
    style="background-image: url(assets/image/about-image.jpg)"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="about-l">
            <ul class="about-link">
              <li class="about-p"><span>Your checkout</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- breadcrumb end -->
<!-- checkout page start -->
<section class="section-tb-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="checkout-area">
          <div class="billing-area">
            <form [formGroup]="checkoutForm">
              <h2>Billing and Shipping Details</h2>
              <div class="billing-form">
                <ul class="billing-ul input-2">
                  <li class="billing-li">
                    <label>Student name</label>
                    <input
                      type="text"
                      formControlName="first_name"
                      placeholder="Student name"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['first_name'].invalid &&
                          (this.checkoutForm.controls['first_name'].dirty ||
                            this.checkoutForm.controls['first_name'].touched)
                      }"
                    />
                  </li>
                  <li class="billing-li">
                    <label>Section</label>
                    <input
                      type="text"
                      formControlName="last_name"
                      placeholder="Section"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['last_name'].invalid &&
                          (this.checkoutForm.controls['last_name'].dirty ||
                            this.checkoutForm.controls['last_name'].touched)
                      }"
                    />
                  </li>
                </ul>

                <ul class="billing-ul">
                  <li class="billing-li">
                    <label>Door No / Flat No</label>
                    <input
                      type="text"
                      formControlName="address1"
                      placeholder=""
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['address1'].invalid &&
                          (this.checkoutForm.controls['address1'].dirty ||
                            this.checkoutForm.controls['address1'].touched)
                      }"
                    />
                  </li>
                </ul>
                <ul class="billing-ul">
                  <li class="billing-li">
                    <label>Address and Landmark</label>
                    <input
                      type="text"
                      formControlName="address2"
                      placeholder="Street address"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['address2'].invalid &&
                          (this.checkoutForm.controls['address2'].dirty ||
                            this.checkoutForm.controls['address2'].touched)
                      }"
                    />
                  </li>
                </ul>
                <ul class="billing-ul">
                  <li class="billing-li">
                    <label>Town / City</label>
                    <input
                      type="text"
                      formControlName="city"
                      placeholder=""
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['state'].invalid &&
                          (this.checkoutForm.controls['state'].dirty ||
                            this.checkoutForm.controls['state'].touched)
                      }"
                    />
                  </li>
                </ul>
                <ul class="billing-ul">
                  <li class="billing-li">
                    <mat-form-field
                      class="example-full-width"
                      appearance="fill"
                    >
                      <mat-label>State</mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="state"
                        [matAutocomplete]="auto"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['state'].invalid &&
                            (this.checkoutForm.controls['state'].dirty ||
                              this.checkoutForm.controls['state'].touched)
                        }"
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="stateonSelect($event.option.value)"
                      >
                        <mat-option
                          *ngFor="let state of stateslist"
                          [value]="state"
                        >
                          {{ state }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <!-- <label>State</label>
                                        <select formControlName="state" placeholder="" [ngClass]="{'invalid-input': this.checkoutForm.controls['state'].invalid 
                                        && (this.checkoutForm.controls['state'].dirty || this.checkoutForm.controls['state'].touched)}">
                                    <option value="Telangana">Telangana</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    </select> -->
                    <!-- <input type="text" > -->
                  </li>
                </ul>
                <ul class="billing-ul">
                  <li class="billing-li">
                    <label>Postcode / ZIP</label>
                    <input
                      pattern="[1-9]{1}[0-9]{5}"
                      type="text"
                      maxlength="6"
                      formControlName="zipcode"
                      (keypress)="keyPressNumbers($event)"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['zipcode'].invalid &&
                          (this.checkoutForm.controls['zipcode'].dirty ||
                            this.checkoutForm.controls['zipcode'].touched)
                      }"
                    />
                  </li>
                  <li class="billing-li">
                    <label>Email address</label>
                    <input
                      type="email"
                      formControlName="emailid"
                      placeholder="Email address"
                      ngModel
                      [email]="true"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['emailid'].invalid &&
                          (this.checkoutForm.controls['emailid'].dirty ||
                            this.checkoutForm.controls['emailid'].touched)
                      }"
                    />
                  </li>
                  <li class="billing-li">
                    <label>Alternate Mobile</label>

                    <input
                      pattern="[1-9]{1}[0-9]{9}"
                      type="text"
                      maxlength="10"
                      placeholder="Mobile"
                      (keypress)="keyPressNumbers($event)"
                      id="alternumberID"
                      formControlName="altno"
                      (input)="numberChange1($event)"
                      placeholder="Alternate Mobile"
                      [ngClass]="{
                        'invalid-input':
                          this.checkoutForm.controls['altno'].invalid &&
                          (this.checkoutForm.controls['altno'].dirty ||
                            this.checkoutForm.controls['altno'].touched)
                      }"
                    />
                  </li>
                </ul>
                <ul class="billing-ul input-2">
                  <li class="comment-area">
                    <div>
                      <input
                        style="
                          float: left;
                          width: 15px;
                          margin-right: 10px;
                          margin-top: 3px;
                        "
                        type="checkbox"
                        [checked]="true"
                        formControlName="terms"
                      />
                      <!-- <input style="float: left;
                                            width: 15px;
                                            margin-right: 10px;
                                            margin-top: 3px;" type="checkbox" [checked]="true" formControlName="terms" [ngClass]="{'invalid-input': this.checkoutForm.controls['terms'].invalid 
                                            && (this.checkoutForm.controls['terms'].dirty || this.checkoutForm.controls['terms'].touched)}" ngModel required> -->
                      <label style="color: red"
                        >Note: I agree that order will be delivered only within
                        15 km from school.</label
                      >
                      <label style="color: red"
                        >More than 15 km or out of station please contact given
                        numbers 7995551101/02</label
                      >
                    </div>

                    <!-- <input formControlName="amount" value="{{getTotal() + afterdiscount }}"> -->
                  </li>
                  <li class="comment-area">
                    <label>Order notes(Optional)</label>
                    <textarea
                      formControlName="notes"
                      rows="4"
                      cols="80"
                    ></textarea>

                    <!-- <input formControlName="amount" value="{{getTotal() + afterdiscount }}"> -->
                  </li>

                  <input type="text" formControlName="country" readonly />
                </ul>
              </div>
            </form>
          </div>
          <div class="order-area">
            <div class="check-pro">
              <h2>
                In your cart ({{ CartItemsList ? CartItemsList.length : 0 }})
              </h2>
              <ul class="check-ul">
                <li *ngFor="let item of CartItemsList">
                  <div class="check-pro-img">
                    <a
                      ><img
                        src="https://amigosbooks.com/bag-images/{{
                          item.images
                        }}.jpg"
                        class="img-fluid"
                        alt="image"
                    /></a>
                  </div>
                  <div class="check-content">
                    <a>{{ item.name }}</a>
                    <span class="check-code-blod"
                      >SKU: <span>{{ item.sku }}</span></span
                    >
                    <span class="check-price"
                      >&#8377;{{ item.price | number : "1.2-2" }}</span
                    >
                  </div>
                </li>
              </ul>
            </div>
            <h2>Your order</h2>
            <ul class="order-history">
              <li class="order-details">
                <span>Product:</span>
                <span>Total</span>
              </li>

              <li
                class="order-details"
                style="text-align: left"
                *ngFor="let item of CartItemsList"
              >
                <div>
                  {{ item.name }}<br />

                  <span style="font-size: 10px">
                    {{ item.quantity }} x &#8377;
                    {{ item.price | number : "1.2-2" }} </span
                  ><br />
                </div>
                <span
                  >&#8377;{{
                    item.quantity * item.price | number : "1.2-2"
                  }}</span
                >
              </li>
              <li class="order-details" style="text-align: left">
                <div>
                  Subtotal:<br />

                  <span style="font-size: 10px">
                    (Includes CGST {{ getCGSTTotal() | number : "1.2-2" }} and
                    SGST {{ getSGSTTotal() | number : "1.2-2" }} ) </span
                  ><br />
                </div>
                <span>&#8377;{{ getTotal() | number : "1.2-2" }}</span>
              </li>
              <li class="order-details">
                <span>Shipping Charge:</span>
                <span>&#8377;{{ getShippingTotal() | number : "1.2-2" }}</span>
              </li>
              <li class="order-details">
                <span>Gateway Charges:</span>
                <span>&#8377;{{ gatewayCharges | number : "1.2-2" }}</span>
              </li>
              <!-- <li class="order-details">
                                <span>Discount Amount:</span>
                                <span>&#8377;{{fnCalculateDiscountAmt()}}.00</span>
                            </li> -->

              <li class="order-details">
                <span>Total:</span>
                <span
                  >&#8377;{{
                    getTotal() + afterdiscount + gatewayCharges
                      | number : "1.2-2"
                  }}</span
                >
              </li>
            </ul>
            <form>
              <ul class="order-form">
                <li class="pay-icon">
                  <a href="javascript:void(0)"
                    ><i class="fa fa-credit-card"></i
                  ></a>
                  <a href="javascript:void(0)"><i class="fa fa-cc-visa"></i></a>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-cc-paypal"></i
                  ></a>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-cc-mastercard"></i
                  ></a>
                </li>
              </ul>
            </form>
            <div class="checkout-btn">
              <button
                type="submit"
                [disabled]="this.checkoutForm.invalid"
                class="btn btn-style1"
                (click)="btnPlaceOrder()"
              >
                Proceed to Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
