import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howtoget-school-id',
  templateUrl: './howtoget-school-id.component.html',
  styleUrls: ['./howtoget-school-id.component.css']
})
export class HowtogetSchoolIdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
