<section class="slider">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="assets/image/slider1.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets/image/slider2.jpg"
          alt="Second slide"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <!-- <div class="home-slider owl-carousel owl-theme">

        <div class="items">
            <div class="img-back" style="background-image:url(assets/image/slider2.jpg);">
              
            </div>
        </div>

    </div> -->
</section>
<!--home page slider start-->

<!-- Our Products Tab start -->
<section class="our-products-tab section-tb-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="grid-list-area">
          <div class="search-dg-m search-dg-d">
            <strong style="font-size: 1.2em; color: black"
              ><span
                class="top-c"
                style="animation: blinker 0.7s infinite; color: black"
              >
                Note: </span
              >Please enter school code.</strong
            >
          </div>
          <div class="grid-list-select mt-0">
            <div class="search-dg-m search-dg-d">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Please Enter School Code</mat-label>
                <input
                  matInput
                  placeholder="Ex. AB"
                  id="scode"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
              <button mat-raised-button color="accent" (click)="getProducts()">
                Go
              </button>
            </div>
          </div>
          <div (click)="openSchoolID()" class="schoolid">
            How to get school code?
          </div>

          <div class="grid-list-select" *ngIf="shownameandfilter">
            <ul class="grid-list-selector">
              <li>
                <h2>{{ DisplaySchoolName }} Books</h2>
              </li>
            </ul>
            <ul class="grid-list-selector">
              <li>
                <mat-form-field appearance="fill">
                  <mat-label>Sort by Class</mat-label>
                  <mat-select
                    [formControl]="selected_class"
                    multiple
                    (selectionChange)="onClassChange()"
                  >
                    <!-- <mat-select-trigger>
                                            {{selected_class.value ? selected_class.value[0].class_name : ''}}
                                            <span *ngIf="selected_class.value?.length > 1"
                                                class="example-additional-selection">
                                                (+{{selected_class.value.length - 1}} {{selected_class.value?.length ===
                                                2 ? 'other' : 'others'}})
                                            </span>
                                        </mat-select-trigger> -->
                    <mat-option
                      *ngFor="let class of classObject"
                      [value]="class"
                    >
                      {{ class.class_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
            </ul>
          </div>
          <div class="grid-pro">
            <ul class="grid-product">
              <li class="grid-items" *ngFor="let products of productObject">
                <div class="tred-pro">
                  <div class="tr-pro-img" style="cursor: pointer !important">
                    <a>
                      <img
                        class="img-fluid"
                        src="https://amigosbooks.com/bag-images/{{
                          products.images
                        }}.jpg"
                        alt="{{ products.name }}"
                      />
                      <!-- <img class="img-fluid additional-image" src="{{products.images}}" alt="additional image"> -->
                    </a>
                  </div>
                  <div class="Pro-lable">
                    <span class="p-text">New</span>
                  </div>
                </div>
                <div class="caption">
                  <h3>{{ products.name }}</h3>
                  <div class="rating">
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                  </div>
                  <div class="pro-price">
                    <span class="new-price"
                      >&#8377; {{ products.price | number : "1.2-2" }} INR</span
                    >
                  </div>
                  <div class="pro-icn" style="margin-top: 5px">
                    <!-- tst--{{IsProductAddedToCart(products.id)}} -->
                    <button
                      class="w-c-q-icn buy-btn-desktop-green buy-btn-mobile-green"
                      style="cursor: pointer"
                      [disabled]="IsProductAddedToCart(products.id)"
                      (click)="addtoCheckoutfun(products)"
                    >
                      <i class="fa fa-shopping-bag"> Buy</i>
                    </button>
                    <button
                      class="w-c-q-icn buy-btn-desktop-red buy-btn-mobile-red"
                      style="cursor: pointer"
                      [disabled]="IsProductAddedToCart(products.id)"
                      (click)="addtoCartfun(products)"
                    >
                      <i class="fa fa-shopping-bag"> Add to Cart</i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Products Tab end -->

<!-- Our Products Tab start -->
<section *ngIf="false" class="our-products-tab section-tb-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="grid-list-area">
          <h2 style="text-align: center">Trending Products</h2>

          <div class="grid-pro">
            <ul class="grid-product">
              <li
                class="grid-items"
                *ngFor="let products of simpleproductObject"
              >
                <div class="tred-pro">
                  <div class="tr-pro-img" style="cursor: pointer !important">
                    <a>
                      <img
                        class="img-fluid"
                        src="https://amigosbooks.com/bag-images/{{
                          products.images
                        }}.jpg"
                        alt="{{ products.name }}"
                      />
                    </a>
                  </div>
                </div>
                <div class="caption">
                  <h3>{{ products.name }}</h3>
                  <div class="rating">
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                    <i class="fa fa-star c-star"></i>
                  </div>
                  <div class="pro-price">
                    <span class="new-price"
                      >&#8377; {{ products.price }} INR</span
                    >
                  </div>
                  <div class="pro-icn" style="margin-top: 5px">
                    <button
                      class="w-c-q-icn buy-btn-desktop-green buy-btn-mobile-green"
                      style="cursor: pointer"
                      [disabled]="IsProductAddedToCart(products.id)"
                      (click)="addtoCheckoutfun(products)"
                    >
                      <i class="fa fa-shopping-bag"> Buy</i>
                    </button>
                    <button
                      class="w-c-q-icn buy-btn-desktop-red buy-btn-mobile-red"
                      style="cursor: pointer"
                      [disabled]="IsProductAddedToCart(products.id)"
                      (click)="addtoCartfun(products)"
                    >
                      <i class="fa fa-shopping-bag"> Add to Cart</i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Products Tab end -->

<!-- Testimonial Start -->
<section class="section-tb-padding testimonial-bg1">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="testi-m owl-carousel owl-theme">
          <div class="items">
            <div class="testimonial-area">
              <span class="tsti-title">Frendly support</span>
              <p>
                I am so happy to find a site where I can shop for unusual items.
                I found what I wanted with ease and purchased it without a
                hitch. The packaging was phenomenal and my book arrived on time
                in perfect condition./p>
              </p>
              <div class="testi-name">
                <h6>Ganapathi</h6>
                <span>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="items">
            <div class="testimonial-area">
              <span class="tsti-title">Frendly support</span>
              <p>
                Could not have asked for better service. Best ever shipping with
                immediate deliveries to multiple addresses. Upon seeing my
                admission of a snafu with one order, Customer Service called
                immediately to assure me they had made correction. My friends
                were delighted to receive their books
              </p>
              <div class="testi-name">
                <h6>Jessica joy</h6>
                <span>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="items">
            <div class="testimonial-area">
              <span class="tsti-title">Frendly support</span>
              <p>
                "I had a question about my order and needed to contact customer
                service. Everyone I spoke to was helpful, courteous, and
                friendly. They made sure my order arrived on time. Great
                service!
              </p>
              <div class="testi-name">
                <h6>Mahmad Ali</h6>
                <span>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="items">
            <div class="testimonial-area">
              <span class="tsti-title">Frendly support</span>
              <p>
                Received the book I ordered in good condition and in a timely
                fashion. Thanks!
              </p>
              <div class="testi-name">
                <h6>Mohan Routu</h6>
                <span>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="items">
            <div class="testimonial-area">
              <span class="tsti-title">Frendly support</span>
              <p>
                The purchase could not have been easier. I would definitely
                purchase from them again.
              </p>
              <div class="testi-name">
                <h6>Jane deo</h6>
                <span>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                  <i class="fa fa-star e-star"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Testimonial end -->
<!-- quick veiw start -->

<section class="quick-view">
  <div
    class="modal fade"
    id="howtobuyVIdeo"
    tabindex="-1"
    aria-labelledby="howtobuyVIdeo"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <iframe
        style="width: 100%; height: 300px"
        src="https://www.youtube.com/embed/j04KezPFRKs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</section>
<!-- quick veiw end -->
