import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EcommerceService } from '../ecommerce.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  CartItemsList: any;
  checksessioneg = false;
  private subscriptionName: Subscription;
  public LoggedUserName = '';
  constructor(private service: EcommerceService, private toastr: ToastrService) { }

  ngOnInit() {
   
    if (sessionStorage.getItem('RegUser')) {
      this.checksessioneg = true;
    }
    this.CartItemsList = this.service.GetAddedCart();
    this.subscriptionName = this.service.getUpdate().subscribe(message => {
      this.CartItemsList = message;
    });
    this.subscriptionName.add(this.service.IsLoggedIn().subscribe((res: boolean) => {
      this.checksessioneg = res;
      this.fngetLoggedUser();

    }));
    if (this.LoggedUserName === '') {
      this.fngetLoggedUser();
    }
  }
  besafe(){
   
     // alert('Handler for .click() called.');
      $('.main-menu-area .active').removeClass('active').addClass('dg');
     // $(this).find('.main-menu-area .active').removeClass("active");
    
  }
  fngetLoggedUser() {
    if (sessionStorage.getItem('RegUser')) {
      // alert('sdsdsd');
      this.service.getShippingAddress(sessionStorage.getItem('RegUser')).subscribe(
        (result: any) => {
          console.log(result, 'Shipping Address and Customer Details');
          if (result) {
            this.LoggedUserName = result.data[0].custfirstname + '  ' + result.data[0].custlastname;
          } else {

          }

        }
      );
    }
  }
  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
    //  this.LoginSubscription.unsubscribe();
  }
  removeFromcart(productId) {
    this.CartItemsList = this.service.RemoveFromThecart(productId);
    this.toastr.success('Removed');
  }
  getTotal() {
    let totalamt = 0;
    this.CartItemsList.forEach((element: any) => {
      totalamt += Number(element.price * element.quantity);
    });
    return totalamt;
  }
  getCGSTTotal() {
    let cgst = 0;
    this.CartItemsList.forEach((element: any) => {
      cgst += Number(element.cgst);
    });
    return cgst;
  }
  getSGSTTotal() {
    let sgst = 0;
    this.CartItemsList.forEach((element: any) => {
      sgst += Number(element.sgst);
    });
    return sgst;
  }
  getShippingTotal() {
    return this.service.fnCalculateShippingAmtWithDiscount();
    /*
    let shippingtotalamt = 0;
    this.CartItemsList.forEach((element: any) => {
      shippingtotalamt += Number(element.shipping_amount);
    });
    return shippingtotalamt;
    */
  }
  funLoaCartItemsSide() {

    this.CartItemsList = this.service.GetAddedCart();
    if (this.CartItemsList === 0) {
      return null;
    }

  }
}
