<div style="width: 90%; margin: 0px auto; padding: 2%;">
    <mat-tab-group mat-align-tabs="start">

        <mat-tab label="My Orders">
            <app-orders></app-orders>
        </mat-tab>
        <mat-tab label="MyAccount">
            <app-updatemyaddress></app-updatemyaddress>
        </mat-tab>
    </mat-tab-group>

</div>