import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EcommerceService } from '../ecommerce.service';


// import jsPDF from 'jspdf';

// import htmlToPdfmake from 'html-to-pdfmake';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// (<any> pdfMake).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-orders-details-popup',
  templateUrl: './orders-details-popup.component.html',
  styleUrls: ['./orders-details-popup.component.css']
})
export class OrdersDetailsPopupComponent implements OnInit {


  allProducts: any;
  OrderDetails: any;
  subTotal = 0;
  shippingTotal = 0;
  grandTotal = 0;
  gatewaycharges = 0;

  displayProducts: any;
  // @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  constructor(private service: EcommerceService,
              @Inject(MAT_DIALOG_DATA) public data: any) {

   // console.log(data, 'dddddddd');
  }

  ngOnInit(): void {

    // this.service.getProducts().subscribe(
    //   (re: any) => {
    //     console.log("allproducts", re.data);
    //     this.allProducts = re.data;
    //   }

    // );
    this.service.getOrderProductDetails(this.data.data).subscribe(
      (results: any) => {
        // tslint:disable-next-line:quotemark
       // console.log("order details", results.data);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < results.data.length; i++) {
          // tslint:disable-next-line:radix
          this.shippingTotal = parseInt(results.data[i].discount_shipping_amount);
          // tslint:disable-next-line:radix
          this.subTotal += parseInt(results.data[i].pro_price);
          // tslint:disable-next-line:radix
          this.gatewaycharges += parseInt(results.data[i].ord_gatewaycharges);
          // this.shippingTotal += parseInt(results.data[i].pro_shipping) ;
          // tslint:disable-next-line:radix
          this.grandTotal += parseInt(results.data[i].pro_price) * parseInt(results.data[i].ord_quantity);
          // this.grandTotal +=  this.grandTotal + this.shippingTotal;

        }
        this.OrderDetails = results.data;
      }
    );

  }



  // public downloadAsPDF() {
  //   const doc = new jsPDF();

  //   const pdfTable = this.pdfTable.nativeElement;

  //   var html = htmlToPdfmake(pdfTable.innerHTML);

  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).open();

  // }
}
