import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EcommerceService } from '../ecommerce.service';
import { DialogData } from '../orders/orders.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit, AfterViewInit {
  bagresult: any;
  InvoiceData: any;
  onlyBags: any = [];
  bag_items: any;
  studentandpaymentInfo: any;
  CgstSgst: any = [];
  totalAmount = 0;
  loadingstatus = false;
  loadingstatus1 = false;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  studentandpaymentInfo1: any;
  bill_order_number: any;
  bill_date_paid: any;
  bill_first_name: any;
  bill_last_name: any;
  bill_phone: any;
  TQ: any;
  TAT: any;

  constructor(private service: EcommerceService, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.service.getEcommerecInvoice_TOTAL(this.data.data).subscribe((responce: any) => {
      debugger
      this.TQ = responce.data[0].Totalqty;
      this.TAT = responce.data[0].Total_sellprice;

    });
    this.service.getOrderandInvoice_detaildforEcommerce(this.data.data).subscribe((res: any) => {
      console.log(res, 'Items List');
      this.InvoiceData = res.data;
      this.onlyBags = [];
      this.InvoiceData.filter((i: any) => i.name).forEach(ele => {
        if (this.onlyBags.filter((j: any) => j == ele.name).length == 0) {
          this.onlyBags.push(ele.name);
        }
      });
    });
    this.loadingstatus1 = true;
    this.service.getinvoiceCustomerEcommerce(this.data.data).subscribe((res: any) => {
      this.studentandpaymentInfo1 = res.data1;
      this.bill_order_number = res.data1[0].order_number;
      this.bill_date_paid = res.data1[0].date_paid;
      this.bill_phone = res.data1[0].phone;
      this.bill_first_name = res.data1[0].first_name;
      this.bill_last_name = res.data1[0].last_name;
    });
    this.service.getEcommerecInvoice(this.data.data).subscribe((res: any) => {
      this.bagresult = res.data;
      console.log(res, 'Iovoicedata for tax');
      this.bag_items = res.data;
      this.studentandpaymentInfo = res.data1;

    });

    this.loadingstatus1 = false;
  }
  fnGetResultBybagName(name: string) {
    return this.bag_items.filter(i => i.name == name);
  }
  functionMat() {
    // this.bag_items.forEach((ele1: any) => {
    //   this.totalQuantity = Number(this.totalQuantity) + Number(ele1.qty);
    //  return this.TOTALAMOUNT += parseFloat(ele1.qty) * parseFloat(ele1.sell_price);
    // });
    this.bag_items.forEach((ele1: any) => {
      if (this.CgstSgst.filter(i => Number(i.tax_cgst) === Number(ele1.tax_cgst)
        && Number(i.tax_sgst) === Number(ele1.tax_sgst)).length === 0) {
        const cgstsgstitem = {
          tax_cgst: ele1.tax_cgst,
          tax_sgst: ele1.tax_sgst,
          tax_amount_cgst: ele1.tax_amount_cgst,
          tax_amount_sgst: ele1.tax_amount_sgst,
        }
        this.CgstSgst.push(cgstsgstitem);
      }
      else {
        this.CgstSgst.filter(i => Number(i.tax_cgst) == Number(ele1.tax_cgst)
          && Number(i.tax_sgst) === Number(ele1.tax_sgst)).forEach((ele2) => {
            ele2.tax_amount_cgst = (parseFloat(ele2.tax_amount_cgst) + parseFloat(ele1.tax_amount_cgst)).toFixed(2);
            ele2.tax_amount_sgst = (parseFloat(ele2.tax_amount_sgst) + parseFloat(ele1.tax_amount_sgst)).toFixed(2);
          });
      }
    });
  }
  ngAfterViewInit() {
    this.functionMat();
  }


  public downloadAsPDF() {
    this.loadingstatus = true;
    html2canvas(this.pdfTable.nativeElement).then((canvas) => {




      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'in', 'a5'); // A4 size page of PDF
      var position = 0;
      let width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      pdf.addImage(contentDataURL, 'PNG', 0, position, width, height);
      pdf.save('Invoice.pdf'); // Generated PDF
    });
  }
}
