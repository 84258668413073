<mat-dialog-content class="mat-typography">

    <main id="pdfTable" #pdfTable>


        <section class="content-main">



            <div class="card">
                <header class="card-header">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-8">
                            <span>
                    <!-- <i class="material-icons md-calendar_today"></i> <b>Wed, Aug 13, 2020, 4:34PM</b>   -->
                     Order ID: <b>{{OrderDetails[0].ord_ordernumber}}</b>  <br>
                     School Name ID:<b> {{OrderDetails[0].sc_schoolname}}</b>
                  </span>
                        </div>
                        <!-- <div class="col-lg-4 col-md-4 ms-auto text-md-end">

                            <a class="btn btn-secondary ms-2" (click)="downloadAsPDF()"><i class="icon material-icons md-print"></i></a>
                        </div> -->
                    </div>
                </header>
                <!-- card-header end// -->
                <div class="card-body">

                    <div class="row mb-5 order-info-wrap">
                        <div class="col-md-6">
                            <article class="icontext align-items-start">

                                <div class="text">
                                    <strong>Billing Address </strong>
                                    <p class="mb-1">
                                        <strong> Name:</strong> {{OrderDetails[0].cus_firstname}} {{OrderDetails[0].cus_lastname}}<br>
                                        <strong> Email:</strong> {{OrderDetails[0].cus_email}} <br>

                                        <strong>City: </strong>{{OrderDetails[0].ship_city}}<br>
                                        <strong> Location:</strong> {{OrderDetails[0].ship_address1}} <br>
                                        <strong> Pincode:</strong> {{OrderDetails[0].ship_postcode}} <br>
                                        <br> <strong>  Mobile: </strong>{{OrderDetails[0].username}}
                                        <br> <strong>  Alternative Mobile: </strong>{{OrderDetails[0].ship_phone}}
                                        <br> <strong>  Address: </strong>{{OrderDetails[0].ship_address2}}
                                        <br> <strong>  Order Notes: </strong> {{OrderDetails[0].ord_notes}}

                                    </p>
                                </div>
                            </article>
                        </div>

                    </div>
                    <!-- row // -->

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table border table-hover table-lg" width='100'>
                                    <thead>
                                        <tr>
                                            <th width="40%">Product</th>
                                            <th width="20%">item Price</th>
                                            <th width="20%">Quantity</th>
                                            <th width="20%" class="text-end">Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let items of OrderDetails">
                                            <td>
                                                <a class="itemside" href="#">
                                                    <div class="left">
                                                        <img src="{{items.pro_images}}" width="40" height="40" class="img-xs" alt="Item">
                                                    </div>
                                                    <div class="info"> {{items.pro_name}}</div>
                                                </a>
                                            </td>
                                            <td> ₹{{items.pro_price | number : '1.2-2'}}</td>
                                            <td>{{items.ord_quantity}}</td>
                                            <td class="text-end"> ₹{{items.pro_price * items.ord_quantity | number : '1.2-2' }} </td>
                                        </tr>


                                    </tbody>
                                </table>
                                <table style="float: right;">
                                    <tr>
                                        <td>Subtotal: </td>
                                        <td>₹ {{grandTotal | number : '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Shipping and Handling:</td>
                                        <td>₹{{OrderDetails[0].discount_shipping_amount | number : '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Gateway Charges:</td>
                                        <td>₹{{OrderDetails[0].ord_gatewaycharges | number : '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Grand total:</td>
                                        <td><strong>₹{{ grandTotal + shippingTotal + gatewaycharges | number : '1.2-2'}} </strong></td>
                                    </tr>

                                </table>
                            </div>
                            <!-- table-responsive// -->
                        </div>
                        <!-- col// -->
                        <div class="col-lg-12">
                            <div class="box shadow-sm bg-light">
                                <h6>Payment info</h6>
                                <p>
                                    #Order No : {{OrderDetails[0].ord_ordernumber}} <br>#Transaction No : {{OrderDetails[0].ord_transaction_id}} <br> Reason: {{OrderDetails[0].ord_reason}}
                                </p>
                            </div>

                        </div>
                        <!-- col// -->

                    </div>
                </div>
                <!-- card-body end// -->
            </div>
            <!-- card end// -->



        </section>
        <!-- content-main end// -->
    </main>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>