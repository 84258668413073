import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EcommerceService } from '../ecommerce.service';
declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  public registerotp = false;
  public hideformbox = true;
  public numbertwoway: string;
  shownumberexitsworn = false;
  timeLeft = 6000;
  interval;
  constructor(public dialogRef: MatDialogRef<any>, private fb: FormBuilder, private toastr: ToastrService, private service: EcommerceService, private router: Router,) {

  }

  ngOnInit() {

    sessionStorage.removeItem('RegUser');
    sessionStorage.removeItem('fname');
    sessionStorage.removeItem('lname');
    sessionStorage.removeItem('email');
    this.service.SetIsLogin();

    // tslint:disable-next-line:variable-name
    // tslint:disable-next-line:prefer-const
    this.registerForm = this.fb.group({
      fname: ['', [Validators.required]],
      lname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      number: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      otp: [window.btoa(Math.floor(100000 + Math.random() * 900000).toString())],
    });

  }
  validateOtp(otp) {
    debugger;
    const otpobject = JSON.parse(sessionStorage.getItem('customerresgiter'));
    const oot = window.atob(otpobject.otp);
    if (Number(otp) === Number(oot)) {
      debugger;
      this.service.checkOtponDB(otpobject).subscribe((results: any) => {

        console.log(results.data, 'oTP');
        // tslint:disable-next-line:quotemark
        if (results.data === 'activated') {
          this.toastr.success('Your account verfied');
          sessionStorage.removeItem('customerresgiter');
          sessionStorage.setItem('RegUser', this.registerForm.controls.number.value);
          this.service.SetIsLogin();
          if (this.dialogRef.close !== undefined) {
            // tslint:disable-next-line:no-debugger
            debugger;
            this.dialogRef.close();
          } else {
            // tslint:disable-next-line: no-debugger
            debugger;
            const products = JSON.parse(sessionStorage.getItem('CartProducts'));
            if (products.length > 0) {
              // tslint:disable-next-line:no-debugger
              debugger;
              this.router.navigate(['/checkout']);
            } else {
              // tslint:disable-next-line:no-debugger
              debugger;
              this.router.navigate(['/home']);
            }

            if (products === null) {
              debugger
              this.router.navigate(['/home']);
            }
            if (products === undefined) {
              this.router.navigate(['/home']);

            }
          }


        } else {
          this.toastr.error('Please Try Again', 'Technical Error!');
        }
      });
    } else {
      this.toastr.error('Please Enter Valid OTP');
    }

  }
  numberChange(event) {
    debugger;
    this.shownumberexitsworn = false;


    if (event.target.value.length === 10) {
      this.service.checkuserexist(event.target.value).subscribe((results: any) => {
        //    console.log(results.data, 'lavanyaaa');
        if (results.data === 'newuser') {
          this.shownumberexitsworn = false;
        } else {
          this.shownumberexitsworn = true;
        }
      });
    }
  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  btnLoginClick() {
    if (this.dialogRef.close !== undefined) {
      this.dialogRef.close('login');
    } else {
      this.router.navigate(['/login']);
    }
  }
  funregUser() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 6000;
      }
    }, 1000);
    debugger;
    if (this.registerForm.valid) {
      this.registerotp = true;
      this.hideformbox = false;
      this.service.regiterUser(this.registerForm.value).subscribe((results: any) => {
        //   console.log(results);
        if (results && results.data.indexOf('success') > -1) {
          this.toastr.success('Product added', 'Successfully!');
          sessionStorage.setItem('RegUser', this.registerForm.controls.number.value);
          this.service.SetIsLogin();
          this.registerForm.reset();
        } else {
          this.toastr.error(results.data);
        }
      });
    } else {
      this.toastr.error('enter all inputs');
    }
  }
}
