import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CCAvenuePaymentComponent } from "../ccavenue-payment/ccavenue-payment.component";
import { EcommerceService } from "../ecommerce.service";
import { LoginComponent } from "../login/login.component";
// import { ExternalLibraryService } from '../rozerpay';
declare var $: any;
declare let Razorpay: any;

@Component({
  selector: "app-check-out",
  templateUrl: "./check-out.component.html",
  styleUrls: ["./check-out.component.css"],
})
export class CheckOutComponent implements OnInit, AfterViewInit {
  discountAmt = 0;
  afterdiscount = 0;
  gatewayCharges = 0;
  orderIdFromOrdersTable = 1;
  customeridIdFromcustomersTable: any;
  OrginalShippingAmout: any;
  DicountShippingAmout: any;
  constructor(
    private service: EcommerceService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  CartItemsList: any;
  checkoutForm: FormGroup;
  razorpayResponse: string;
  showModal: boolean;
  response: string;

  stateslist: string[] = [
    "Telangana",
    "Andhra Pradesh",
    "Maharashtra",
    "Odisha",
    "Tamil Nadu",
    "Karnataka",
    "Kerala",
    "Rajasthan",
  ];
  SavesdOrderId = "";

  ngOnInit() {
    this.service.getOrdersdtaCount().subscribe((ele) => {
      const orderid: any = ele.data[0].order_number.split("-")[1];

      this.orderIdFromOrdersTable = Number(orderid) + 1;
    });
    this.service
      .getCustomersdtaCount(sessionStorage.getItem("RegUser"))
      .subscribe((ele) => {
        // tslint:disable-next-line:radix
        this.customeridIdFromcustomersTable = ele.data[0].id;
      });
    this.checkoutForm = this.fb.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      address1: ["", [Validators.required]],
      address2: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      country: [""],
      zipcode: ["", [Validators.required]],
      emailid: ["", [Validators.required, Validators.email]],
      altno: ["", [Validators.required]],
      notes: [""],
      latitude: [""],
      langitude: [""],
      customer_id: [],
      products: [],
      totalAmt: [0],
      Orginal_Shipping_Amout: [],
      After_Dicount_Shipping_Amout: [],
      Dicount_Shipping_Amout: [],
      order_number: [""],
      transaction_id: [],
      paymentStatus: [""],
      amount: [],
      terms: [""],
      gatewaycharges: [0],

      tid: Math.floor(Math.random() * 9014944550),
      merchant_id: 119058,
      order_id: [""],
      currency: "INR",
      redirect_url: "https://amigosbooks.com/payment/ccavRequestHandler.php",
      cancel_url: "https://amigosbooks.com/payment/ccavResponseHandler.php",
      language: "EN",
    });
    if (sessionStorage.getItem("RegUser")) {
      this.checkoutForm.controls.customer_id.setValue(
        sessionStorage.getItem("RegUser").toString()
      );
      this.service
        .getShippingAddress(sessionStorage.getItem("RegUser"))
        .subscribe((result: any) => {
          //  console.log(result, 'Shipping Address on');
          this.checkoutForm.controls.first_name.setValue(
            result.data[0].first_name
          );
          this.checkoutForm.controls.last_name.setValue(
            result.data[0].last_name
          );
          this.checkoutForm.controls.address1.setValue(
            result.data[0].address_1
          );
          this.checkoutForm.controls.address2.setValue(
            result.data[0].address_2
          );
          this.checkoutForm.controls.city.setValue(result.data[0].city);
          this.checkoutForm.controls.state.setValue(result.data[0].state);
          this.checkoutForm.controls.zipcode.setValue(result.data[0].postcode);
          this.checkoutForm.controls.emailid.setValue(result.data[0].email);
          this.checkoutForm.controls.altno.setValue(result.data[0].phone);
        });
    }
    setTimeout(() => {
      const shippingbillingdataxx = JSON.parse(
        localStorage.getItem("shippingbillingdata")
      );
      this.checkoutForm.controls.first_name.patchValue(
        shippingbillingdataxx[0].first_name
      );
      this.checkoutForm.controls.last_name.patchValue(
        shippingbillingdataxx[0].last_name
      );
      this.checkoutForm.controls.address1.patchValue(
        shippingbillingdataxx[0].address1
      );
      this.checkoutForm.controls.address2.patchValue(
        shippingbillingdataxx[0].address2
      );
      this.checkoutForm.controls.city.patchValue(shippingbillingdataxx[0].city);
      this.checkoutForm.controls.state.patchValue(
        shippingbillingdataxx[0].state
      );
      this.checkoutForm.controls.zipcode.patchValue(
        shippingbillingdataxx[0].zipcode
      );
      this.checkoutForm.controls.emailid.patchValue(
        shippingbillingdataxx[0].emailid
      );
      this.checkoutForm.controls.altno.patchValue(
        shippingbillingdataxx[0].altno
      );
    }, 300);

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.showPosition);
    // }

    this.CartItemsList = this.service.GetAddedCart();
    if (this.CartItemsList.length === 0) {
      this.router.navigate(["/home"]);
    }
    this.setfirmdatata();
  }
  ngAfterViewInit() {
    const gatewayChargesCalc = this.getShippingTotal() + this.getTotal();
    this.gatewayCharges = (gatewayChargesCalc * 1) / 100;
    this.checkoutForm.controls.gatewaycharges.setValue(this.gatewayCharges);
    const products = JSON.parse(sessionStorage.getItem("CartProducts"));
    if (products.length > 0) {
      this.checkoutForm.controls.country.setValue(products[0].school_name);
    }
  }
  stateonSelect(value) {
    this.checkoutForm.controls.state.setValue(value);
  }
  keyPressNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  // showPosition(position) {
  //   this.checkoutForm.controls.latitude.setValue(position.coords.latitude);
  //   this.checkoutForm.controls.longitude.setValue(position.coords.longitude);
  // }
  removeFromcart(productId) {
    this.CartItemsList = this.service.RemoveFromThecart(productId);
  }
  getTotal() {
    let totalamt = 0;
    this.CartItemsList.forEach((element: any) => {
      totalamt += Number(element.price * element.quantity);
    });
    return totalamt;
  }
  getCGSTTotal() {
    let cgst: any = 0;
    this.CartItemsList.forEach((element: any) => {
      cgst += Number(element.cgst);
    });
    return cgst;
  }
  getSGSTTotal() {
    let sgst = 0;
    this.CartItemsList.forEach((element: any) => {
      sgst += Number(element.sgst);
    });
    return sgst;
  }

  getShippingTotal() {
    // this.discountAmt = this.service.fnCalculateDiscountAmt();
    this.afterdiscount = this.service.fnCalculateShippingAmtWithDiscount();

    return this.afterdiscount;
    // this.service.fnCalculateShippingAmtWithDiscount();
    // this.discountAmt = 0;
    // let shippingtotalamt = 0;
    // this.CartItemsList.forEach((element: any) => {
    //   shippingtotalamt += Number(element.shipping_amount) * element.quantity;
    // });
    // this.discountAmt = this.service.fnCalculateDiscountAmt();//this.CartItemsList);

    // return shippingtotalamt - this.discountAmt;
  }
  Orginal_Shipping_Amout() {
    this.OrginalShippingAmout =
      this.service.fnCalculateShippingAmtWithoutDiscount();
    return this.OrginalShippingAmout;
  }
  Dicount_Shipping_Amout() {
    this.DicountShippingAmout = this.service.fnCalculateDiscountAmt();
    return this.DicountShippingAmout;
  }

  btnPlaceOrder() {
    const shippingbillingdata = JSON.parse(
      localStorage.getItem("shippingbillingdata")
    );
    if (shippingbillingdata == null) {
      const Billling = [];
      const billingObject = {
        first_name: this.checkoutForm.controls.first_name.value,
        last_name: this.checkoutForm.controls.last_name.value,
        address1: this.checkoutForm.controls.address1.value,
        address2: this.checkoutForm.controls.address2.value,
        city: this.checkoutForm.controls.city.value,
        state: this.checkoutForm.controls.state.value,
        zipcode: this.checkoutForm.controls.zipcode.value,
        emailid: this.checkoutForm.controls.emailid.value,
        altno: this.checkoutForm.controls.altno.value,
        notes: this.checkoutForm.controls.notes.value,
      };
      Billling.push(billingObject);

      localStorage.setItem("shippingbillingdata", JSON.stringify(Billling));
    }

    if (sessionStorage.getItem("RegUser")) {
      this.checkoutForm.controls.customer_id.setValue(
        sessionStorage.getItem("RegUser").toString()
      );
    }
    if (
      this.customeridIdFromcustomersTable === undefined ||
      this.customeridIdFromcustomersTable === "undefined"
    ) {
      this.router.navigate(["/login"]);
      return;
    }
    this.checkoutForm.controls.order_id.setValue(
      this.customeridIdFromcustomersTable + "-" + this.orderIdFromOrdersTable
    );
    // this.checkoutForm.controls.order_id.setValue(this.orderIdFromOrdersTable);

    const amt =
      Number(this.getShippingTotal()) +
      Number(this.getTotal()) +
      Number(this.gatewayCharges);
    // tslint:disable-next-line:radix
    const amtnew = Number(amt).toFixed(2);
    const aa = amtnew;
    this.checkoutForm.controls.amount.setValue(aa);
    // alert(typeof amtnew + parseFloat(amtnew, 2).toFixed(2));

    this.checkoutForm.controls.Orginal_Shipping_Amout.setValue(
      this.Orginal_Shipping_Amout()
    );
    this.checkoutForm.controls.After_Dicount_Shipping_Amout.setValue(
      this.getShippingTotal()
    );
    this.checkoutForm.controls.Dicount_Shipping_Amout.setValue(
      this.Dicount_Shipping_Amout()
    );

    // this.checkoutForm.controls.amount.setValue(1);
    this.checkoutForm.controls.products.setValue(
      JSON.stringify(this.CartItemsList)
    );

    if (sessionStorage.getItem("RegUser")) {
      const dialogRef = this.dialog.open(CCAvenuePaymentComponent, {
        width: "400px",

        data: { formobj: this.checkoutForm.value },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //   console.log(`Dialog result: ${result}`); // Pizza!
      });

      // this.service.checkOut(this.checkoutForm.value).subscribe(
      //   (result: any) => {
      //     this.toastr.error(result);
      //     this.dialog.open(result);
      //     console.log(result);
      //     if (result != null) {

      //       // this.fnCallPayment(result.data);
      //     }

      //   }
      // );
    } else {
      this.service.fnShowLoginDialog(LoginComponent, this.checkoutForm);
      // this.checkoutForm.controls.altno.setValue('');
    }
  }
  setfirmdatata() {
    const customerautofill = JSON.parse(
      localStorage.getItem("customerautofill")
    );

    this.checkoutForm.controls.first_name.setValue(
      customerautofill.fname.toString()
    );
    this.checkoutForm.controls.last_name.setValue(
      customerautofill.lname.toString()
    );
    this.checkoutForm.controls.emailid.setValue(
      customerautofill.email.toString()
    );
  }
  numberChange1(event) {
    if (event.target.value.length === 10) {
      // tslint:disable-next-line:prefer-const
      let typedval = event.target.value;
      // tslint:disable-next-line:prefer-const
      let num = sessionStorage.getItem("RegUser");
      if (typedval === num) {
        this.toastr.error("Please try alternate second number", " ", {
          timeOut: 5000,
        });
        this.checkoutForm.controls.altno.setValue("");
      }
    }
  }

  demofunvtion() {}
}
