<!-- breadcrumb start -->
<section class="about-breadcrumb">
    <div class="about-back section-tb-padding" style="background-image: url(assets/image/about-image.jpg)">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="about-l">
                        <ul class="about-link">
                            <li class="about-p"><span>Register</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- breadcrumb end -->
<!-- login start -->
<section class="section-tb-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="register-area">
                    <div class="register-box">
                        <h1>Create Account</h1>
                        <p>Please register below account detail</p>
                        <!-- <form [formGroup]="registerForm"> -->
                        <form [formGroup]="registerForm" *ngIf="hideformbox">
                            <div class="form-group">
                                <input type="text" name="name" class="mt-1" placeholder="Student name" formControlName="fname" [ngClass]="{'invalid-input': this.registerForm.controls['fname'].invalid 
                            && (this.registerForm.controls['fname'].dirty || this.registerForm.controls['fname'].touched)}">
                            </div>
                            <div class="form-group">
                                <input type="text" placeholder="Parent's name" class="mt-1" formControlName="lname" [ngClass]="{'invalid-input': this.registerForm.controls['lname'].invalid 
                                && (this.registerForm.controls['lname'].dirty || this.registerForm.controls['lname'].touched)}">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" formControlName="email" class="mt-1" ngModel [email]="true" [ngClass]="{'invalid-input': this.registerForm.controls['email'].invalid 
                                && (this.registerForm.controls['email'].dirty || this.registerForm.controls['email'].touched)}">


                            </div>
                            <div class="form-group">
                                <input type="text" value="91" disabled style="width: 9%;
                                float: left;
                                margin-top: 5px;
                                padding: 3px;text-align: center;
                                height: 37px;">

                                <input pattern="[1-9]{1}[0-9]{9}" type="text" maxlength="10" placeholder="Mobile" (keypress)="keyPressNumbers($event)" style="width: 91%; float: right;" formControlName="number" class="mt-1" [(ngModel)]="numbertwoway" (input)="numberChange($event)" [ngClass]="{'invalid-input': this.registerForm.controls['number'].invalid 
                                && (this.registerForm.controls['number'].dirty || this.registerForm.controls['number'].touched)}">
                                <label style="font-size: 10px; font-weight: bold;">{{numbertwoway}} <span style="color: red;" *ngIf="shownumberexitsworn">Number Already Registered</span></label>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="mt-1" formControlName="password" [ngClass]="{'invalid-input': this.registerForm.controls['password'].invalid 
                                && (this.registerForm.controls['password'].dirty || this.registerForm.controls['password'].touched)}">
                                <span style="color: red;">Note: Password Must At least 8 characters in length | 
                                    Lowercase letters |
                                    Uppercase letters |
                                    Numbers |
                                    Special characters</span>
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-primary mt-2" (click)="funregUser();" [disabled]="this.registerForm.invalid || shownumberexitsworn">Create</button>
                            </div>




                            <!-- <button class="btn-style1" (click)="funregUser()" [disabled]="this.registerForm.invalid">Create</button> -->
                        </form>

                        <div *ngIf="registerotp">
                            <!-- <div> -->
                            <strong style="color:green">OTP is Sent to Your Mobile Number and Valid for 10 Min </strong>
                            <!-- {{timeLeft}} -->
                            <input type="number" #otpval> <br>
                            <button type="button" class="btn btn-primary mt-2" (click)="validateOtp(otpval.value)">Verify</button>

                        </div>

                    </div>
                    <div class="register-account">
                        <h4>Already an account holder?</h4>
                        <a class="ceate-a" (click)="btnLoginClick()" routerLinkActive="router-link-active">Log in</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- login end -->