import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CartComponent } from './cart/cart.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomepagesliderComponent } from './homepageslider/homepageslider.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BesafeComponent } from './besafe/besafe.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { DisputeResolutionComponent } from './dispute-resolution/dispute-resolution.component';
import { GeneralTermsandConditionsComponent } from './general-termsand-conditions/general-termsand-conditions.component';
import { UpdatemyaddressComponent } from './updatemyaddress/updatemyaddress.component';
import { OrdersComponent } from './orders/orders.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CCAvenuePaymentComponent } from './ccavenue-payment/ccavenue-payment.component';
import { HowtogetSchoolIdComponent } from './howtoget-school-id/howtoget-school-id.component';
import { TrackingOrderPurchaseComponent } from './tracking-order-purchase/tracking-order-purchase.component';
import { OrdersDetailsPopupComponent } from './orders-details-popup/orders-details-popup.component';
import { InvoiceComponent } from './invoice/invoice.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    PageNotFoundComponent,
    CartComponent,
    CheckOutComponent,
    LoginComponent,
    RegisterComponent,
    HomepagesliderComponent,
    CustomerDashboardComponent,
    AboutusComponent,
    BesafeComponent,
    ContactusComponent,
    PhotogalleryComponent,
    ShippingPolicyComponent,
    CancellationPolicyComponent,
    PrivacyPolicyComponent,
    ContactInformationComponent,
    ReturnPolicyComponent,
    RefundPolicyComponent,
    DisputeResolutionComponent,
    GeneralTermsandConditionsComponent,
    UpdatemyaddressComponent,
    OrdersComponent,
    ForgotpasswordComponent,
    CCAvenuePaymentComponent,
    HowtogetSchoolIdComponent,
    TrackingOrderPurchaseComponent,
    OrdersDetailsPopupComponent,
    InvoiceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    HttpClientModule,
    MatButtonModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [{ provide: MatDialogRef, useValue: {} },
  ],
  entryComponents: [LoginComponent, InvoiceComponent,
     OrdersDetailsPopupComponent, ForgotpasswordComponent, RegisterComponent, CCAvenuePaymentComponent, HowtogetSchoolIdComponent, TrackingOrderPurchaseComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
