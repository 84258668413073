<div style="width: 90%; margin: 0px auto; padding: 2%;">
    Terms of Use <br> You are welcome to Amigos Books You can avail the services offered here or through its affiliates, but prior to that you need to agree to the terms and conditions. If you browse our site or visit our shop, you have to accept these
    conditions.
    <br> Make sure that you have read the terms and conditions properly. While availing current or future services that are offered by the site or its affiliates, you will abide by these conditions and guidelines that are applicable.
    <br> The Privacy Policy
    <br> Make sure that you thorough review the privacy policy that governs the visit to our site. You shall understand our practices properly.
    <br> Terms and conditions for all purchases
    <br> The orders are for you to buy products from us. We will accept your offer by issuing a conformation email or by confirming through mobile about the products that you have ordered. These acceptances are subject to your assent to the conditions
    of sale. No other conditions will be applicable in this context.
    <br> Methods of Electronic Communication
    <br> As you visit our site or send any emails to use, you actually are communicating electronically. With this communication you give the consent for receiving communication from our end electronically. We can post notices to our website or communicate
    with your directly through mail. You will have to agree that all the notices and any other forms of communications that are provided by us electronically completely satisfy any kinds of legal necessities and such communications are in writing terms.
    <br> Prices
    <br> The prices that we post in this website can be changed without any notice. The prices that are available before the commencement of order will be applicable. The prices that are posted also include charges and taxes. In case, if any additional
    charges, it will be notified on the website.
    <br> Conditions of Payment
    <br> The payments will have to reach us before shipping. We can accept payment through net banking, credit card, debit card and even mobile payment.
    <br> About Shipping and Handling
    <br> As you place the order, we will make the arrangements for shipping the products to you. The schedules of shipping are previously. At the same time, we are liable for any delays in the procedure of shipment. The risk of loss and damage is passed
    to you as soon as the product is delivered to you. We cannot arrange for reverse shipment because of the unavailability of our logistic partners.
    <br> Access to License and Website
    <br> General: The site gives you limited license through which you can access the personal use of the website for downloading or modifying any portion of the content without the consent of the site.
    <br> Commercial sale:The license does not include any kind of commercial use of website or resale of the content. If there is any collection or listing of any product along with pricing, description or information of account, it can benefit another
    merchant.
    <br> No reproduction: The website cannot be reproduced or duplicated or exploited for commercial purpose without the written consent of the site.
    <br> No framing: You cannot frame or utilize any technology of framing for enclosing trademarks, logos or other information on the site. You will require a written consent for that.
    <br> Use of Metatags: You are not permitted to use metatags or hidden texts for utilizing the name or the trademark of the company without any written consent. If you use it, you will be terminated from the permission of licenses that are granted
    by the company.
    <br> About Your Account
    <br> Protection: As per the privacy policy of the website, you are responsible for maintaining the confidentiality of the account and password you have, so that you can restrict access to your computer. In addition to that, you also accept the responsibility
    of all the activities that occur under your password.
    <br> Communications
    <br> Wrong information: You cannot use false email address or cannot impersonate anyone which can mislead the original or a content or card. In such a case, we reserve the right to edit or remove the content and will not review posted contents regularly.
    <br> The Rights: If you submit material or post contents with a different intention, you can grant us and our affiliates a royalty free and non-exclusive perpetual and sub-licensed right to use the material. We can modify, reproduce, publish, adapt
    and create derivative work throughout the world. You also give Amigos Books and the other affiliate sites the sub license for using the name that you have submitted in connection with the content.
    <br> Owning Rights: You can represent all the rights or control the right for the content that you have posted and the content is accurate. You can also use it for supplying but not violating the policy, so that it does not cause any injury to any
    entity. You will also accept for indemnification of the affiliate for the claims that will result in the supply of the content. The site has the right to obligate or edit or even remove the activity of the content. It takes no responsibility for liability
    of any content that you have posted.
    <br> The Risk of Loss
    <br> The items that you have purchased from this site are adhered to the contract of shipment. Thus, the title and the risk of loss pass to you as soon as the product is delivered.
    <br> Description of Product
    <br> Amigos Books and the affiliates try to be as much accurate. However, there are no warranties that description of the product or the content is accurate or reliable or complete. The product offered by it is also not described.
    <br> Policies of the Site, Modification, and Severability
    <br> You should review our other policies, as well. We have the right to make any change in our websites, the terms and conditions and the policies any time. If these conditions are invalid, unenforceable or void for any reason, the condition can
    be deemed as severable. It will also not create any affect on the validity or enforceability of other conditions.
    <br> Rights of Intellectual Property
    <br> Copyright: All the contents like graphics, texts, button icons, logos, digital downloads, audio clips is the property of Amigos Books and is completely protected by Indian law of Copyright. The content is also the exclusive property of the site
    including the software in use.
    <br> Trademarks:
    <br> Protected Marks: The protected marks include Amigos Books & design and other things that are in the website.
    <br> Protected Graphics: The graphics, page headers, logos, icons, scripts are trademarks of the site. These trademarks and trade dress might not be used in relation to products and services that are not of the company. All the other trademarks are
    owned by the company and its affiliate sites. These are the property of different owners. They might not be connected to or affiliated with Amigos Books.
    <br> Disclaimer of warranties and Limitation of Liability
    <br> The complete site is provided by Amigos Books on the basis of as is and as available. The company does not represent or warrantee any kind of operations. It also does not express or implies the information on the site or the operation along with
    the additional materials, contents or products that are part of this site. It is you as a customer who agrees that the use of this site is solely at your own risk.
    <br> As applicable by law, Amigos Books disclaims all the warrantees to the full extent. It also disclaims the expression or implication that are not limited to but implied with the warrantees of merchantability as well as fitness. These conditions
    are stated for a special purpose. The site also does not warrantee that the total site, the servers, the emails sent are completely free of any harmful components or viruses. However, the site will not take the liability of any damages that arises
    from the usage of the site that also includes any kind if incidental, direct, indirect or kinds of consequential damages, as a whole.

    <br>


</div>