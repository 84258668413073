import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EcommerceService } from '../ecommerce.service';
declare var $: any;
@Component({
  selector: 'app-updatemyaddress',
  templateUrl: './updatemyaddress.component.html',
  styleUrls: ['./updatemyaddress.component.css']
})
export class UpdatemyaddressComponent implements OnInit {
  checkoutForm: FormGroup;
  stateslist: string[] = ['Telangana', 'Andhra Pradesh', 'Maharashtra', 'Odisha', 'Tamil Nadu', 'Karnataka', 'Kerala', 'Rajasthan'];

  constructor(private service: EcommerceService, private toastr: ToastrService, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    this.checkoutForm = this.fb.group({

      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['India'],
      zipcode: ['', [Validators.required]],
      emailid: ['', [Validators.required, Validators.email]],
      altno: ['', [Validators.required]],
      notes: [''],
      latitude: [''],
      langitude: [''],
      customer_id: [],
      products: [],
      totalAmt: [0],
      ShippingCharges: [],
      order_number: [''],
      transaction_id: [],
      paymentStatus: [''],
      amount: [''],
      username: [sessionStorage.getItem('RegUser')]

    });
    if (sessionStorage.getItem('RegUser')) {
      this.checkoutForm.controls.customer_id.setValue(sessionStorage.getItem('RegUser').toString());
      this.service.getAddressFromShipping(sessionStorage.getItem('RegUser')).subscribe(
        (result: any) => {
          //   console.log(result, 'Get UserAddress');
          this.checkoutForm.controls.first_name.setValue(result.data[0].first_name);
          this.checkoutForm.controls.last_name.setValue(result.data[0].last_name);
          this.checkoutForm.controls.address1.setValue(result.data[0].address_1);
          this.checkoutForm.controls.address2.setValue(result.data[0].address_2);
          this.checkoutForm.controls.city.setValue(result.data[0].city);
          this.checkoutForm.controls.state.setValue(result.data[0].state);
          this.checkoutForm.controls.zipcode.setValue(result.data[0].postcode);
          this.checkoutForm.controls.emailid.setValue(result.data[0].email);
          this.checkoutForm.controls.altno.setValue(result.data[0].phone);

        }
      );
    }
  } stateonSelect(value) {
    this.checkoutForm.controls.state.setValue(value);
  }
  numberChange1(event) {
    if (event.target.value.length === 10) {
      // tslint:disable-next-line:prefer-const
      let typedval = event.target.value;
      // tslint:disable-next-line:prefer-const
      let num = sessionStorage.getItem('RegUser');
      if (typedval === num) {
        this.toastr.error('Please try alter native second number', ' ', { timeOut: 5000 });
        // $('#alternumberID').val('');
        this.checkoutForm.controls.altno.setValue('');
      }
    }
  }
  UpdateAddress() {
    // tslint:disable-next-line:no-unused-expression
    this.service.updateAddress(this.checkoutForm.value).subscribe(
      ele => {
        if (ele.data === 'updated') {
          this.toastr.success('Address Updated Successfully', '', {
            timeOut: 5000,
          });
        }

      }
    );


  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
