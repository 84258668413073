<div style="width: 90%; margin: 0px auto; padding: 2%;">

    <h3>SHIPPING POLICY</h3>
    <p><strong>How much are the delivery charges?</strong><br> Amigos Books distributorscharges for delivery on orders depending on the value, weight and dimensions of the product, which may range up to 200 IND as delivery charges.<br>
        <br>
        <strong>What is the estimated delivery time?</strong> </p>
    It may differ from one item to another. Please read below for an explanation of delivery estimates:<br>
    <br> Mentioned as 3-5 days delivery time: These items will also be mentioned as &quot;In Stock&quot;. For all areas serviced by reputed couriers the delivery time will be 3-5 business days. Business days exclude public holidays and Sunday.<br>
    <br> Mentioned as more than 3 days delivery time, when natural calamities happen or on Public Holiday otherwise you will receive your order within the time specified.&nbsp;<br>
    <br>
    <br>
    <strong>How will the delivery be done?</strong><br> We process alldeliveries through our delivery agents. All deliver agents are liable to amigosbooks.<br>
    <br> If there is no courier service available in your area, we will get in touch with you and try to work out a convenient alternate delivery location that is serviced by our courier partners.<br>
    <br> All Books are insured for theft &amp; damages during transit.<br>
    <br>
    <strong>What about warranty and hidden costs (sales tax, octroi etc.)?</strong><br> There are no extra taxes or hidden costs. The price mentioned on the website is the final price. What you see is what you pay. Our prices are all inclusive. All taxes
    are included with the list prices.<br>
    <br>

</div>