<!-- breadcrumb start -->
<section class="about-breadcrumb">
    <div class="about-back section-tb-padding" style="background-image: url(assets/image/about-image.jpg)">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="about-l">
                        <ul class="about-link">
                            <li class="about-p"><span>Register</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- breadcrumb end -->
<!-- login start -->
<section class="section-tb-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="register-area">
                    <div class="register-box">
                        <h1>Forgot Password</h1>
                        <!-- <form [formGroup]="registerForm"> -->
                        <form [formGroup]="registerForm" *ngIf="hideformbox">

                            <div class="form-group">
                                <input type="text" value="91" disabled style="    width: 4%;
                                float: left;
                                margin-top: 14px;
                                padding: 0px;
                                text-align: center;
                                background: none;
                                border: none;">
                                <input pattern="[1-9]{1}[0-9]{9}" type="text" maxlength="10" (keypress)="keyPressNumbers($event)" placeholder="Mobile" style="width: 95%; float: right;" formControlName="number" class="mt-1" [(ngModel)]="numbertwoway" (input)="numberChange($event)">
                                <!-- <label style="font-size: 10px; font-weight: bold;">{{numbertwoway}} <span style="color: green;" *ngIf="shownumberexitsworn">Number already Registerd</span></label> -->

                            </div>

                            <div class="form-group">
                                <button type="button" class="btn btn-primary mt-2" (click)="funregUser();" [disabled]="this.registerForm.invalid || shownumberexitsworn">Enter </button>
                            </div>


                            <!-- <button class="btn-style1" (click)="funregUser()" [disabled]="this.registerForm.invalid">Create</button> -->
                        </form>

                        <div *ngIf="registerotp">
                            <!-- <div> -->
                            <!-- <div> -->
                            <strong style="color:green">OTP is Sent to Your Mobile Number and Valid for 10 Min </strong>
                            <!-- {{timeLeft}} -->
                            <input type="number" #otpval> <br>
                            <button type="button" class="btn btn-primary mt-2" [disabled]="otpbtn" (click)="validateOtp(otpval.value)">Verify</button>

                            <form [formGroup]="updatePassForm" *ngIf="forgotOTPvertified">
                                <input type="password" id="passs" placeholder="Enter New Password" formControlName="password"> <br>
                                <button type="button" class="btn btn-primary mt-2" (click)="updatePassword()">Set Password</button>

                            </form>
                        </div>

                    </div>
                    <div class="register-account">
                        <h4>Already an account holder?</h4>
                        <a class="ceate-a" (click)="btnGigisterClick()" routerLinkActive="router-link-active">Log in</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- login end -->