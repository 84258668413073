import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { EcommerceService } from '../ecommerce.service';
import { DialogData } from '../orders/orders.component';

@Component({
  selector: 'app-tracking-order-purchase',
  templateUrl: './tracking-order-purchase.component.html',
  styleUrls: ['./tracking-order-purchase.component.css', './tracking-order-purchase.component.less']
})
export class TrackingOrderPurchaseComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private service: EcommerceService) { }
  trackinghistor: any;

  ngOnInit() {
    debugger;
    const dataa = {
      id: 0,
      orderid: this.data.data.toString()
    };
    this.service.getOrderLiveStatusforCustomer(dataa).subscribe(
      ele => {
     //   console.log(ele, 'orders tracking');
        this.trackinghistor = ele.data;
      }
    );
  }
  fnchecktrackerStatus(type: string): boolean {
    return this.trackinghistor.filter((i: any) => i.status === type).length > 0;
  }
  fnchecktrackerDate(type: string): string {
    return this.trackinghistor.filter((i: any) => i.status === type)[0].date;
  }

}
