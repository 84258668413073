<div style="width: 90%; margin: 0px auto; padding: 2%;">
    <h3>Refund Policy</h3>
    <p>This Refund Policy applies to the online purchase of books </p>
    <p>1.    General </p>
    <p> (a)We offer refunds and replacements in accordance with the Indian  Law and on the terms set out in this Refund Policy . </p>
    <p>(b)Any benefits set out in this Policy may apply in addition to consumer's rights under the Indian Consumer Law. <br> (c)Before making a purchase, please read this Policy so that you can understand your rights and what you can expect from us if you
        are not satisfied with your order. </p>
    <p>2.    Indian Consumer Law </p>
    <p>(a)Under the Indian Consumer Law: </p>
    <p>(i)Our goods and services come with guarantees that cannot be excluded under the Indian Consumer Law. For major failures with the service, you are entitled <br> (A)to cancel your service contract with us; and </p>
    <p>                                (B)to a refund for the unused portion, or to compensation for its reduced value. <br> (ii)You are also entitled to choose a refund or replacement for major failures with goods. If a failure with the goods or a service
        does not amount to a major failure, you are entitled to have the failure rectified in a reasonable time. If this is not done you are entitled to a refund for the goods and to cancel the contract for the service and obtain a refund. You are also
        entitled to be compensated for any other reasonably foreseeable loss or damage from a failure in the goods or service. <br> (b)We offer refunds, and replacements in accordance with the Indian consumer Law. </p>
    <p>(c)The Indian Consumer Law provides a set of Consumer Guarantees which protect consumers when they buy the products. </p>
    <p>(d)If the Indian Consumer Law applies, then we cannot avoid the Consumer Guarantees which it provides. If there is an inconsistency between this Policy and the Indian Consumer Law, the Indian Consumer Law will prevail</p>
    <p>(e)If a product which you purchased from us has a major failure (as defined in the Indian Consumer Law) then you may be entitled to a replacement or refund. <br> (f)If a product which you purchased from us has a failure which does not amount to a
        major failure (as defined in the Indian Consumer Law) then you may still be entitled to have the goods replaced. </p>
    <p>3.    Cancellation and Change of Mind </p>
    <p>(a)We do not offer any refund if you change your mind, or find the same product or cheaper elsewhere. </p>
    <p>4.    Products Damaged During Delivery </p>
    <p>(a)In the event that the product you ordered has been damaged during delivery: <br> (i)Please contact us as soon as possible. <br> (ii)Any damaged product must be returned in the condition in which it was received, together with any packaging and
        other items which you received with the damaged product. <br> (b)We will arrange to collect the damaged product and replace it with an equivalent product, or to refund it, provided that you have contacted us within 7 days from the date of receiving
        the product. </p>
    <p>5.    Exceptions </p>
    <p>(a)Notwithstanding the other provisions of this Policy, we may refuse to provide a replacement or refund for a product or service purchased by you if: <br> (i)You misused the said product in a way which caused the problem. </p>
    <p>(ii)You knew or were made aware of the problem(s) with the product or service before you purchased it. <br> (iii)You asked for a service to be done in a certain manner, or you asked for alterations to a product, against our advice, or you were unclear
        about what you wanted. <br> (iv)Any other exceptions that apply under the Indian Consumer Law. </p>
    <p>6.    Shipping Costs for Returns</p>
    <p>(a)In the event that a product you have purchased fails to meet one or more Consumer Guarantees under the Indian Consumer Law, we shall bear any cost of shipping the product back to us, as well as any cost of shipping any replacement product to you.
        <br> (b)If the Returned Product can easily be shipped or returned, then you are responsible for organising for the Returned Product to be returned to us. If the Returned Product is eligible for a replacement or refund under the terms of this Policy
        (including under the Indian Consumer Law) then we will reimburse you for the reasonable postage, shipping or transportation costs for the Returned Product. <br> (c)In the event that we organise and pay for the inspection, postage, shipping, transportation
        or collection of a Returned Product, and it turns out not to be <br> eligible for a repair, replacement or refund under the terms of this Policy (including under the Indian Consumer Law), then you will be required to pay the <br> costs of any
        inspection, postage, shipping, transportation or collection of the </p>
    <p>Returned Product. </p>
    <p>7.    Response Time </p>
    <p>(a)We aim to process any requests for replacements or refunds within 14 working days of receipt. </p>
    <p>8.    How to Return Products </p>
    <p>(a)You can contact us at the end of this Policy to discuss a return using the information. <br> (b)Unless otherwise defined in our sole discretion, we shall pay all refunds in the same form as the original purchase or to the same account or credit
        card used to make the original purchase. <br> (c)To be eligible for a refund or replacement, you must provide proof of purchase. </p>
    <p>(d)You may be required to provide a government issued identification to qualify for a refund or replacement.</p>
    <p>9.    Contact Us </p>
    <p>(a)If you wish to write to us about this Policy or about any refund, repairs or replacements, please contact us at: amigosorders@amigosace.com. </p>

</div>