<!-- breadcrumb start -->
<section class="about-breadcrumb">
  <div
    class="about-back section-tb-padding"
    style="background-image: url(assets/image/about-image.jpg)"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="about-l">
            <ul class="about-link">
              <li class="about-p"><span>Your shopping cart</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- breadcrumb end -->
<!-- cart start -->
<section class="cart-page section-tb-padding">
  <div class="container">
    <div class="row">
      <div class="col-xl-8 col-xs-12 col-sm-12 col-md-12 col-lg-7">
        <div class="cart-item">
          <span class="cart-head">My cart:</span>
          <span class="c-items"
            >{{ CartItemsList ? CartItemsList.length : 0 }} item</span
          >
          <!-- <h3 style="color: red;">{{showlimitqty}}</h3> -->
        </div>

        <div class="cart-area" *ngFor="let item of CartItemsList">
          <div class="cart-details">
            <div class="cart-all-pro">
              <div class="cart-pro">
                <div class="cart-pro-image">
                  <a href="#"
                    ><img
                      src="https://amigosbooks.com/bag-images/{{
                        item.images
                      }}.jpg"
                      class="img-fluid"
                      alt="image"
                      style="height: 100px"
                  /></a>
                </div>
                <div class="pro-details">
                  <h4>
                    <a> {{ item.name }}</a>
                  </h4>
                  <span class="pro-size"
                    ><span class="size">SKU:</span> {{ item.sku }}</span
                  >
                  <span class="pro-shop">--</span>
                  <span class="cart-pro-price"
                    >&#8377; {{ item.price | number : "1.2-2" }} INR</span
                  >
                </div>
              </div>
              <div class="qty-item">
                <div class="center">
                  <div class="plus-minus">
                    <span>
                      <a
                        href="javascript:void(0)"
                        class="text-black"
                        (click)="btnMinusClick(item)"
                        >-</a
                      >
                      <input
                        type="text"
                        name="name"
                        min="1"
                        [value]="item.quantity"
                      />
                      <a
                        href="javascript:void(0)"
                        class="text-black"
                        (click)="btnPlusClick(item)"
                        >+</a
                      >
                    </span>
                  </div>
                  <a class="pro-remove" (click)="removeFromcart(item.id)"
                    >Remove</a
                  >
                </div>
              </div>
              <div class="all-pro-price">
                <span
                  >&#8377;
                  {{ item.price * item.quantity | number : "1.2-2" }} INR</span
                >
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="CartItemsList.length === 0 ? true : false"
          style="text-align: center; padding: 20%; font-size: 2em"
        >
          No Items in Cart
          <a [routerLink]="['/home']" routerLinkActive="router-link-active"
            ><strong> Click Here for Products</strong></a
          >
        </div>
      </div>
      <div class="col-xl-4 col-xs-12 col-sm-12 col-md-12 col-lg-5">
        <div class="cart-total">
          <div class="shop-total">
            <div>
              Subtotal:<br />

              <span style="font-size: 10px">
                (Includes CGST {{ getCGSTTotal() | number : "1.2-2" }} and SGST
                {{ getSGSTTotal() | number : "1.2-2" }} )</span
              ><br />
            </div>
            <span class="total-amount"
              >&#8377;{{ getTotal() | number : "1.2-2" }} INR</span
            >
          </div>
          <div class="shop-total">
            <span>Shipping and Handling </span>
            <span class="total-amount"
              >&#8377;{{ getShippingTotal() | number : "1.2-2" }} INR</span
            >
            <!-- <span class="total-amount">Discount &#8377;{{discountAmt}} INR</span> -->
          </div>
          <div class="shop-total">
            <span><strong>Order Total</strong> </span>
            <span class="total-amount"
              >&#8377;{{
                getTotal() + afterdiscount | number : "1.2-2"
              }}
              INR</span
            >
          </div>
          <a
            [routerLink]="['/checkout']"
            routerLinkActive="router-link-active"
            class="btn btn-style1"
            >Checkout</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<!-- cart end -->
