import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EcommerceService } from '../ecommerce.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  CartItemsList: any;
  QuantityValue = 1;
  showlimitqty = '';
  discountAmt = 0;
  afterdiscount=0;
  constructor(private service: EcommerceService, private toastr: ToastrService) { }

  ngOnInit() {
    this.CartItemsList = this.service.GetAddedCart();
  }
  removeFromcart(productId) {
    this.CartItemsList = this.service.RemoveFromThecart(productId);
  }
  getTotal() {
    let totalamt = 0;
    this.CartItemsList.forEach((element: any) => {
      totalamt += Number(element.price) * Number(element.quantity);
    });
    return totalamt;
  }
  getCGSTTotal() {
    let cgst = 0;
    this.CartItemsList.forEach((element: any) => {
      cgst += parseFloat(Number(element.cgst).toFixed(2));
    });
    return cgst;
  }
  getSGSTTotal() {
    let sgst = 0;
    this.CartItemsList.forEach((element: any) => {
      sgst += Number(element.sgst);
    });
    return sgst;
  }
 
  getShippingTotal() {
    // this.discountAmt = this.service.fnCalculateDiscountAmt();
    this.afterdiscount = this.service.fnCalculateShippingAmtWithDiscount();
    return this.afterdiscount;
    // this.service.fnCalculateShippingAmtWithDiscount();
    // this.discountAmt = 0;
    // let shippingtotalamt = 0;
    // this.CartItemsList.forEach((element: any) => {
    //   shippingtotalamt += Number(element.shipping_amount) * element.quantity;
    // });
    // this.discountAmt = this.service.fnCalculateDiscountAmt();//this.CartItemsList);

    // return shippingtotalamt - this.discountAmt;
  }
  btnPlusClick(item) {
    if (item.quantity === 10) {
      this.toastr.error('Sorry you can not add more then 10');
      this.showlimitqty = 'Note: Quantity limited to 10';
      return;
    }

    item.quantity = Number(item.quantity) + 1;
    this.service.AddItemsToCart(item);

  }
  btnMinusClick(item) {
    if (item.quantity === 1) {
      // alert('User Cant be less than 1');
      return;
    }
    item.quantity = Number(item.quantity) - 1;
    this.service.AddItemsToCart(item);
  }
}
