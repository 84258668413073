import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EcommerceService } from '../ecommerce.service';
declare var $: any;
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  registerForm: FormGroup;
  updatePassForm: FormGroup;
  otpbtn = false;
  forgotOTPvertified = false;
  public registerotp = false;
  public hideformbox = true;
  public numbertwoway: string;
  shownumberexitsworn = false;
  timeLeft = 6000;
  interval;
  constructor(public dialogRef: MatDialogRef<any>,private fb: FormBuilder, private toastr: ToastrService, private service: EcommerceService, private router: Router, ) {

  }

  ngOnInit() {
    sessionStorage.removeItem('RegUser');
    sessionStorage.removeItem('fname');
    sessionStorage.removeItem('lname');
    sessionStorage.removeItem('email');
    $('#passs').focus();
    // tslint:disable-next-line:variable-name
    // tslint:disable-next-line:prefer-const
    this.registerForm = this.fb.group({
      number: ['', [Validators.required]],
      otp: [window.btoa(Math.floor(100000 + Math.random() * 900000).toString())],
    });
    this.updatePassForm = this.fb.group({
      password: ['', [Validators.required]],
      number: [''],
    });

  }
  validateOtp(otp) {

    const otpobject = JSON.parse(sessionStorage.getItem('update_password_otp'));
    if (Number(otp) === Number(window.atob(otpobject.otp))) {
      this.toastr.success('OTP is Verified Please Update Password Bellow');
      debugger;
      $('#usernameID').val(Number(window.atob(otpobject.number)));
      this.otpbtn = true;
      this.forgotOTPvertified = true;

    } else {
      this.toastr.error('Please Enter Valid OTP');
    }

  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  updatePassword() {

    const otpobject = JSON.parse(sessionStorage.getItem('update_password_otp'));

    this.updatePassForm.controls.number.setValue(Number(otpobject.number));
    this.service.updatePass(this.updatePassForm.value).subscribe((results: any) => {
      debugger;
     // console.log(results);
      if (results.data === 'updated') {
        this.toastr.success('Successfully updated', 'Success', { timeOut: 5000 });
       // this.router.navigateByUrl('login');
        sessionStorage.setItem('RegUser', this.updatePassForm.controls.number.value);
        this.service.SetIsLogin();

        if (this.dialogRef.close !== undefined) {
          this.dialogRef.close();
        } else {
          this.router.navigate(['/home']);
        }
      //  this.router.navigateByUrl('/home');
      }

    });
  }
  numberChange(event) {
    // this.shownumberexitsworn = true;
    if (event.target.value.length === 10) {


      this.service.checkuserexist(event.target.value).subscribe((results: any) => {
      //  console.log(results.data, 'lavanyaaa');
        if (results.data === 'newuser') {
          this.shownumberexitsworn = true;
        } else {
          this.shownumberexitsworn = false;
        }
      });
    }
  }
  btnGigisterClick(){
    if (this.dialogRef.close !== undefined) {
      this.dialogRef.close('login');
    } else {
      this.router.navigate(['/login']);
    }
  }
  funregUser() {
    if (this.registerForm.valid) {
      this.registerotp = true;
      this.hideformbox = false;
      this.service.regiterUser(this.registerForm.value).subscribe((results: any) => {
       // console.log(results);
        if (results && results.data.indexOf('success') > -1) {
          this.toastr.success('Product added', 'Sucessfully!');
          sessionStorage.setItem('update_password_otp', this.registerForm.controls.number.value);

          this.registerForm.reset();
        } else {
          this.toastr.error(results.data);
        }
      });
    } else {
      this.toastr.error('enter all inputs');
    }
  }

}
