<!-- breadcrumb start -->
<section class="about-breadcrumb">
    <div class="about-back section-tb-padding" style="background-image: url(assets/image/about-image.jpg)">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="about-l">
                        <ul class="about-link">
                            <li class="about-p"><span>Login</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- breadcrumb end -->
<!-- login start -->
<section class="section-tb-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="register-area">
                    <div class="register-box">
                        <h1>Login</h1>
                        <form [formGroup]="loginForm">
                            <input pattern="[1-9]{1}[0-9]{9}" type="text" maxlength="10" formControlName="number" placeholder="Mobile" (keypress)="keyPressNumbers($event)" [ngClass]="{'invalid-input': this.loginForm.controls['number'].invalid 
                            && (this.loginForm.controls['number'].dirty || this.loginForm.controls['number'].touched)}">
                            <input type="password" formControlName="password" placeholder="Password" [ngClass]="{'invalid-input': this.loginForm.controls['password'].invalid 
                            && (this.loginForm.controls['password'].dirty || this.loginForm.controls['password'].touched)}">
                            <button type="submit" class="btn btn-primary mt-2" (click)="funLoginUser()" [disabled]="this.loginForm.invalid">
                                Login
                            </button>
                            <a class=" mt-2" style="margin-left: 10px;" routerLinkActive="router-link-active" (click)="btnForgotPwdClick()">
                                <!--[routerLink]="['/forgotpassword']"-->
                                Forgot Password
                            </a>

                        </form>
                    </div>
                    <div class="register-account">
                        <h4>DON'T HAVE AN ACCOUNT?
                        </h4>
                        <a class="ceate-a" (click)="btnGigisterClick()" routerLinkActive="router-link-active">Register</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- login end -->