<div style="width: 90%; margin: 0px auto; padding: 2%;">
    <h3>ReturnsPolicy</h3>
    <p>If you return any product, we will accept if wish to return any product or if it is damaged during shipping or it defective. We will also accept the return, if the product is wrongly delivered to you or the packing is incomplete. However, we should
        be informed about the discrepancies within 72 hours from the delivery date of the product. At the same time, the products should also be returned in the original condition.</p>
    <strong>If I receive a faulty/damaged packaged product from AmigosBooks, what should I do in that case?</strong>
    <p>Non-indulgent quality check for packaging and shipping is compiled by the company for the customer satisfaction. Withal, if there is anything found improper with the products provided by amigosbooks, we will for sure resolve every issue.&nbsp;</p>
    <strong>If I have to return the product ordered fromAmigosBooks, how can I do that?</strong>
    <p>Anyways if the product(s) purchased is no more required by you, you can write to us in that regard on “email-id” within 48 hours (2 days) of obtaining purchased order. You can also return the shipment, if unopened, for a repayment or gift voucher
        for the same amount. However, in such condition, we regret to inform that we would not be able to compensate for the shipping charges spend by you.</p>

</div>